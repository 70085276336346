<template>
    <div class="wrapper -calidad-innovacion padding-menu">
        <div class="breadcrumb">
            <div class="container row-end">
                <router-link to="/">Grupo IAN</router-link>
                <router-link to="/"  style="pointer-events: none;">{{ $t('navbar_menu.quality_innovation.title') }}</router-link>
                <router-link :to="$route.path">{{ $t('quality_security.main_banner.title') }}</router-link>
            </div>
        </div>

        <section class="intro-page">
            <div class="container">
                <h1 class="waypoints">{{ $t('quality_security.main_banner.title') }}</h1>
                <img :src="$t('quality_security.main_banner.img')" alt="" class="waypoints">
                <div class="green-box">
                    <h2>{{ $t('quality_security.main_banner.title') }}</h2>
                    <div v-html="$t('quality_security.main_banner.text')"></div>
                </div>
            </div>
        </section>

        <section class="banner-seguridad">
            <div class="container row-start">
                <div class="texto waypoints">
                    <h2 class="main-title">{{ $t('quality_security.security_banner.title') }}</h2>
                    <div v-html="$t('quality_security.security_banner.text')"></div>
                </div>
                <img :src="$t('quality_security.security_banner.img1')" alt="Innovación y Calidad"
                    class="seguridad-1 waypoints">
                <img :src="$t('quality_security.security_banner.img2')" alt="Innovación y Calidad"
                    class="seguridad-2 waypoints">
                <img :src="$t('quality_security.security_banner.img3')" alt="Innovación y Calidad"
                    class="seguridad-3 waypoints">
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'calidad-e-innovacion',
}
</script>

<style lang="scss">
@media screen and (max-width:1040px) {
    .intro-page img {
        width: 100%;
    }
}
</style>